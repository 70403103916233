import { graphql, Link } from 'gatsby';
import moment from 'moment/moment';
import * as queryString from 'query-string';
import React, { useEffect, useState } from 'react';
import Button from '../../components/Button';
import { Column, Container, Row } from '../../components/Grid';
import Icon from '../../components/Icon';
import Image from '../../components/Image';
import Layout from '../../components/Layout';
import OutboundLink from '../../components/OutboundLink';
import Section from '../../components/Section';
import YouTubeCarousel from '../../components/YouTubeCarousel';

const YoungScientistBlog = ({ data, location }) => {
  const getRawResources = (data) =>
    data.blogs.edges.reduce((acc, curr) => {
      acc.push(curr.node);
      return acc;
    }, []);

  const [filter, setFilter] = useState(null);
  const allResources = getRawResources(data);

  const filteredResources = filter
    ? allResources.filter((res) =>
        moment(res.date).format('YYYY').includes(filter)
      )
    : allResources;

  const increaseNumber = 15;
  const [list, setList] = useState([
    ...filteredResources.slice(0, increaseNumber),
  ]);
  const [loadMore, setLoadMore] = useState(false);
  const [hasMore, setHasMore] = useState(
    filteredResources.length > increaseNumber
  );

  const handleLoadMore = () => {
    setLoadMore(true);
  };

  useEffect(() => {
    const all = queryString.parse(location.search);
    var yearsArray = null;
    try {
      yearsArray = all.years.split(' ').map(Number);
    } catch (error) {
      yearsArray = null;
    }
    setFilter(yearsArray);
  }, [location.search]);

  useEffect(() => {
    setList([...filteredResources.slice(0, increaseNumber)]);
  }, [filteredResources.length]);

  useEffect(() => {
    if (loadMore && hasMore) {
      const currentLength = list.length;
      const isMore = currentLength < filteredResources.length;
      const nextResults = isMore
        ? filteredResources.slice(currentLength, currentLength + increaseNumber)
        : [];
      setList([...list, ...nextResults]);
      setLoadMore(false);
    }
  }, [loadMore, hasMore]);

  //Check if there is more
  useEffect(() => {
    const isMore = list.length < filteredResources.length;
    setHasMore(isMore);
  }, [list]);
  const [isHovered, setIsHovered] = useState(false);
  return (
    <Layout title="Young Scientist Blog" className="news-and-events blog">
      <div className="hero-container">
        <div className="hero-container__gradient bg-gradient-light-blue"></div>
        <div className="hero-container__titleImage">
          {/* Breadcrumbs */}
          <ul className="breadcrumbs">
            <li className="breadcrumb">
              <Link to="../../news-and-events/recent-news">News</Link>
            </li>
            <li className="breadcrumb active">Young Scientist Blog</li>
          </ul>
          <h1 className="page-title">Young Scientist Blog</h1>
          <Image filename="hero-news-and-events.png" className="hero-image" />
        </div>
      </div>
      {/* Recent News Intro */}
      <Section>
        <Container>
          {/* Recent News Intro */}
          <Row>
            <Column size={8} offset={2} className="global-intro">
              <h2 id="news-intro">
                Find out what’s new in the world of science
              </h2>
              <h4>
                Stay connected to all the latest STEM news, important challenge
                updates and much more. Browse regularly updated content like
                blog entries, challenge exclusives, and thought starters, along
                with insights from today’s top young scientists.
              </h4>
            </Column>
          </Row>
        </Container>
      </Section>
      <Section>
        <Container>
          <h3 style={{ borderBottom: '1px solid gray', paddingBottom: '1rem' }}>
            Follow along as the 2024 finalists complete their summer mentorship
            with a 3M scientist!
          </h3>
          <YouTubeCarousel playlistId="PLobAGami2cOpJeqTXfjulTW-NRjJRaC5x" />
          <div
            className="blog__youtube-banner-container"
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
          >
            <a
              href="https://www.youtube.com/@YoungSciChallenge"
              target="_blank"
            >
              <div
                className={`original-component ${!isHovered ? 'visible' : ''}`}
              >
                <div className="blog__youtube-banner">
                  <Icon className="blog__youtube-banner-icon" name="youtube" />
                  <span>Check out our YouTube channel</span>
                  <Icon
                    className="blog__youtube-banner-arrow"
                    name="arrowright"
                  />
                </div>
              </div>
              <div className={`new-component ${isHovered ? 'visible' : ''}`}>
                <div className="blog__youtube-banner blog__youtube-banner--hovered">
                  <Icon className="blog__youtube-banner-icon" name="youtube" />
                  <div className="blog__youtube-banner-text-grid">
                    <div className="blog__youtube-banner-text-hidden">
                      Check out our YouTube channel
                    </div>
                    <div className="blog__youtube-banner-text-visible">
                      Check out our YouTube channel{' '}
                    </div>
                  </div>
                  <Icon
                    className="blog__youtube-banner-arrow"
                    name="arrowright"
                  />
                </div>
              </div>
            </a>
          </div>
        </Container>
      </Section>
      {/* Recent News Main Content */}
      <Section>
        <Container>
          <Row>
            {/* Young Scientist Blog Left Column */}
            <Column size={9}>
              <ul className="filter-tabs news-and-events">
                {/* This will be turned back on AFTER site launch */}
                {/* <li>
                  <Link to="#news-intro">2022</Link>
                </li> */}
                <li className="filter-tabs__tab">
                  <Link to="../recent-news#news-intro">Program Updates</Link>
                </li>
                <li className="filter-tabs__tab selected">
                  <Link to="../ysc-blog#news-intro">Young Scientist Blog</Link>
                </li>
                {/* This will be turned back on AFTER site launch */}
                {/* <li className="filter-tabs__tab">
                <li className="filter-tabs__tab selected">
                <Link to="../alumni-news#news-intro">Alumni</Link>
              </li> */}
              </ul>
              {/* YSC Blog Post */}
              {list.map((node, index) => (
                <div className="post" key={index}>
                  <div className="post__thumbnail">
                    <Image
                      filename={
                        node.thumbnail || '/placeholders/Placeholder-Card.jpg'
                      }
                    />
                  </div>
                  <div className="post__content">
                    <h3 className="post__content-title">{node.title}</h3>
                    <h4 className="post__content-date">
                      Submitted by <span className="author">{node.author}</span>{' '}
                      on {moment(node.date).format('MMMM DD, YYYY')}
                    </h4>
                    <p className="post__content-teaser">
                      {node.text[0].substring(0, 300)}
                      {node.text[0].length > 300 && <span>...</span>}
                    </p>
                    <Link
                      to={`/news-and-events/ysc-blog/${moment(node.date).format(
                        'YYYY'
                      )}/${node.slug}`}
                      className="post__content-cta"
                    >
                      Learn More &gt;
                    </Link>
                  </div>
                </div>
              ))}

              <div className="d-flex justify-content-center">
                {hasMore && (
                  <Button onClick={handleLoadMore} className="load-more">
                    Load More
                  </Button>
                )}
              </div>
            </Column>
            {/* In The News and Press Releases Right Column */}
            <Column size={3} className="mt-2">
              <div className="right__column-links">
                <h3>In The News</h3>
                <ul className="news__cta-links">
                <li>
                    <OutboundLink
                      to="https://time.com/7010714/time-and-time-for-kids-name-the-2024-kid-of-the-year-15-year-old-scientist-heman-bekele/"
                      className="news__outbound"
                    >
                      <Icon name="externallink" />
                      <h4>Time</h4>
                      <span className="news__cta-date">August 15th, 2024</span>
                      <span className="news__cta-title">
                      Heman Bekele Is TIME's 2024 Kid of the Year
                      </span>
                    </OutboundLink>
                  </li>
                  <li>
                    <OutboundLink
                      to="https://www.forbes.com/sites/janinemaclachlan/2022/04/05/this-is-the-key-to-breakthrough-innovation-according-to-3m-scientist/?sh=5a154fc97e4b"
                      className="news__outbound"
                    >
                      <Icon name="externallink" />
                      <h4>Forbes</h4>
                      <span className="news__cta-date">April 13th, 2022</span>
                      <span className="news__cta-title">
                        This Is The Key To Breakthrough Innovation, According To
                        3M Scientist
                      </span>
                    </OutboundLink>
                  </li>
                  <li>
                    <OutboundLink
                      to="https://patch.com/georgia/smyrna/s/hwlqk/marietta-teen-places-2nd-national-young-scientist-competition"
                      className="news__outbound"
                    >
                      <Icon name="externallink" />
                      <h4>Patch</h4>
                      <span className="news__cta-date">November 2nd, 2021</span>
                      <span className="news__cta-title">
                        Marietta Teen Places 2nd In National Young Scientist
                        Competition
                      </span>
                    </OutboundLink>
                  </li>
                  <li>
                    <OutboundLink
                      to="https://thejournal.com/articles/2021/10/28/2021-young-scientist-challenge-winners-named.aspx"
                      className="news__outbound"
                    >
                      <Icon name="externallink" />
                      <h4>THE Journal</h4>
                      <span className="news__cta-date">October 28th, 2021</span>
                      <span className="news__cta-title">
                        2021 Young Scientist Challenge Winners Named
                      </span>
                    </OutboundLink>
                  </li>
                  <li>
                    <OutboundLink
                      to="https://thriveglobal.com/stories/are-you-americas-next-top-young-scientist/"
                      className="news__outbound"
                    >
                      <Icon name="externallink" />
                      <h4>Thrive Global</h4>
                      <span className="news__cta-date">October 15th, 2021</span>
                      <span className="news__cta-title">
                        Are You America’s Next Top Young Scientist? 3M and
                        Discovery Education Think You Just Might Be!
                      </span>
                    </OutboundLink>
                  </li>
                  <li>
                    <OutboundLink
                      to="https://medium.com/authority-magazine/young-change-makers-why-and-how-anika-chebrolu-is-helping-to-change-our-world-7022f4e87cdb"
                      className="news__outbound"
                    >
                      <Icon name="externallink" />
                      <h4>Authority Magazine</h4>
                      <span className="news__cta-date">
                        September 6th, 2021
                      </span>
                      <span className="news__cta-title">
                        Young Change Makers: Why and How Anika Chebrolu Is
                        Helping To Change Our World
                      </span>
                    </OutboundLink>
                  </li>
                </ul>
              </div>
              <div className="right__column-links">
                <h3>Press Releases</h3>
                <ul className="news__cta-links">
                  <li>
                    <OutboundLink
                      to="https://www.discoveryeducation.com/details/3m-young-scientist-challenge-announces-2024-national-finalists/"
                      className="news__outbound"
                    >
                      <Icon name="externallink" />
                      <h4>June 27th, 2024</h4>
                      <span className="news__cta-title">
                        3M Young Scientist Challenge Announces 2024 National
                        Finalists
                      </span>
                    </OutboundLink>
                  </li>
                  <li>
                    <OutboundLink
                      to="https://www.discoveryeducation.com/details/3m-and-discovery-education-recognize-26-state-merit-winners-and-four-honorable-mentions-in-2024-3m-young-scientist-challenge/"
                      className="news__outbound"
                    >
                      <Icon name="externallink" />
                      <h4>June 26th, 2024</h4>
                      <span className="news__cta-title">
                        3M and Discovery Education Recognize 26 State Merit
                        Winners and Four Honorable Mentions in 2024 3M Young
                        Scientist Challenge
                      </span>
                    </OutboundLink>
                  </li>
                  <li>
                    <OutboundLink
                      to="https://www.discoveryeducation.com/details/search-begins-for-2024-americas-top-young-scientist/"
                      className="news__outbound"
                    >
                      <Icon name="externallink" />
                      <h4>January 16th, 2024</h4>
                      <span className="news__cta-title">
                        Search Begins for 2024 America’s Top Young Scientist
                      </span>
                    </OutboundLink>
                  </li>
                  <li>
                    <OutboundLink
                      to="https://www.discoveryeducation.com/details/3m-young-scientist-challenge-announces-2022-national-finalists-and-honorable-mentions/"
                      className="news__outbound"
                    >
                      <Icon name="externallink" />
                      <h4>July 7th, 2022</h4>
                      <span className="news__cta-title">
                        3M Young Scientist Challenge Announces 2022 National
                        Finalists and Honorable Mentions
                      </span>
                    </OutboundLink>
                  </li>
                  <li>
                    <OutboundLink
                      to="https://www.discoveryeducation.com/details/3m-and-discovery-education-recognize-31-state-merit-winners-in-2022-3m-young-scientist-challenge/"
                      className="news__outbound"
                    >
                      <Icon name="externallink" />
                      <h4>June 27th, 2022</h4>
                      <span className="news__cta-title">
                        3M and Discovery Education Recognize 31 State Merit
                        Winners in 2022 3M Young Scientist Challenge
                      </span>
                    </OutboundLink>
                  </li>
                  <li>
                    <OutboundLink
                      to="https://www.discoveryeducation.com/details/3m-young-scientist-challenge-announces-2021-national-finalists-and-honorable-mentions/"
                      className="news__outbound"
                    >
                      <Icon name="externallink" />
                      <h4>June 24th, 2021</h4>
                      <span className="news__cta-title">
                        3M Young Scientist Challenge Announces 2021 National
                        Finalists and Honorable Mentions
                      </span>
                    </OutboundLink>
                  </li>
                  <li>
                    <OutboundLink
                      to="https://www.discoveryeducation.com/details/3m-and-discovery-education-announce-state-merit-winners-in-the-2021-3m-young-scientist-challenge/"
                      className="news__outbound"
                    >
                      <Icon name="externallink" />
                      <h4>June 23rd, 2021</h4>
                      <span className="news__cta-title">
                        3M and Discovery Education Announce State Merit Winners
                        in the 2021 3M Young Scientist Challenge
                      </span>
                    </OutboundLink>
                  </li>
                  <li>
                    <OutboundLink
                      to="https://www.discoveryeducation.com/details/americas-top-young-scientist-of-2020-14-year-old-researches-spike-protein-of-sars-cov-2-virus-to-develop-novel-antiviral-drug-to-combat-spread-of-covid-19/"
                      className="news__outbound"
                    >
                      <Icon name="externallink" />
                      <h4>October 14th, 2020</h4>
                      <span className="news__cta-title">
                        America’s Top Young Scientist of 2020: 14-Year-Old
                        Researches Spike Protein of SARS-CoV-2 Virus to Develop
                        Novel Antiviral Drug to Combat Spread of COVID-19
                      </span>
                    </OutboundLink>
                  </li>
                </ul>
              </div>
            </Column>
          </Row>
        </Container>
      </Section>
    </Layout>
  );
};

export const data = graphql`
  query {
    blogs: allBlogsYaml(sort: { order: DESC, fields: date }) {
      edges {
        node {
          author
          date
          id
          slug
          text
          thumbnail
          title
          video
        }
      }
    }
  }
`;

export default YoungScientistBlog;
