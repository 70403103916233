import axios from 'axios';
import { useEffect, useState } from 'react';
import { YOUTUBE_API_KEY, YOUTUBE_BASE_URL } from '../config/youtube';

const useYoutubeApi = (endpoint, params = {}) => {
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    console.log('Fetching YouTube data:', endpoint, params);
    const essentialParam = endpoint.includes('videos') ? 'id' : 'playlistId';
    if (
      !params[essentialParam] ||
      (Array.isArray(params[essentialParam]) &&
        params[essentialParam].length === 0)
    ) {
      // Exit early if the essential parameter is missing or empty
      console.log('YouTube API missing essential parameter:', essentialParam);
      setError('Required parameter is missing or empty');
      setData([]);
      return;
    }

    const fetchData = async () => {
      setIsLoading(true);
      try {
        const response = await axios.get(`${YOUTUBE_BASE_URL}/${endpoint}`, {
          params: { key: YOUTUBE_API_KEY, ...params },
        });
        setData(response.data);
      } catch (err) {
        setError(err);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [JSON.stringify(params)]);

  return { data, isLoading, error };
};

export default useYoutubeApi;
