import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useRef } from 'react';
import Slider from 'react-slick';
import useYouTubePlaylistItems from '../../hooks/useYouTubePlaylistItems';
import useYouTubeVideos from '../../hooks/useYouTubeVideos';
import Icon from '../Icon';
import Image from '../Image';
import Modal from '../Modal';
import './styles.scss';

const YouTubeCarousel = ({ settings, playlistId }) => {
  const slickSlider = useRef(null);

  function PrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={classNames(className, 'youtube-carousel__prev-arrow')}
        style={{ ...style }}
        onClick={onClick}
      >
        <Icon name="caratleft" />
      </div>
    );
  }

  function NextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={classNames(className, 'youtube-carousel__next-arrow')}
        style={{ ...style }}
        onClick={onClick}
      >
        <Icon name="caratright" />
      </div>
    );
  }

  const defaultSettings = {
    accessibility: true,
    dots: false,
    infinite: true,
    speed: 750,
    slidesToShow: 3,
    slidesToScroll: 3,
    autoplay: false,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 1023,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
    ],
  };

  const sliderSettings = { ...defaultSettings, ...settings };

  const { playlistDetails } = useYouTubePlaylistItems(playlistId);

  const videoIds =
    playlistDetails?.map((item) => item.contentDetails?.videoId) || [];

  const { videoDetails } = useYouTubeVideos(videoIds);

  const formatDuration = (duration) => {
    const matches = duration.match(/PT(\d+H)?(\d+M)?(\d+S)?/);
    const hours = matches[1] ? matches[1].slice(0, -1) : '0';
    const minutes = matches[2] ? matches[2].slice(0, -1) : '0';
    const seconds = matches[3] ? matches[3].slice(0, -1) : '0';

    // Convert hours to minutes if there are any hours
    const totalMinutes = parseInt(hours, 10) * 60 + parseInt(minutes, 10);
    const formattedSeconds = parseInt(seconds, 10).toString().padStart(2, '0');
    return `${totalMinutes}:${formattedSeconds}`;
  };

  const videos = videoDetails?.map((video) => ({
    thumbnailUrl:
      video.snippet?.thumbnails?.maxres?.url ||
      video.snippet?.thumbnails?.high?.url ||
      video.snippet?.thumbnails?.medium?.url ||
      video.snippet?.thumbnails?.standard?.url ||
      video.snippet?.thumbnails?.default?.url ||
      '/placeholders/Placeholder-Card.jpg',
    title: video.snippet?.title,
    views: video.statistics?.viewCount,
    uploadDate: video.snippet?.publishedAt,
    duration: formatDuration(video.contentDetails?.duration),
    id: video.id,
  }));

  const timeSince = (dateString) => {
    const date = new Date(dateString);
    const seconds = Math.floor((new Date() - date) / 1000);

    let interval = seconds / 31536000; // Number of seconds in a year

    if (interval >= 1) {
      const years = Math.floor(interval);
      return years + (years === 1 ? ' year ago' : ' years ago');
    }
    interval = seconds / 2592000; // Number of seconds in a month
    if (interval >= 1) {
      const months = Math.floor(interval);
      return months + (months === 1 ? ' month ago' : ' months ago');
    }
    interval = seconds / 604800; // Number of seconds in a week
    if (interval >= 1) {
      const weeks = Math.floor(interval);
      return weeks + (weeks === 1 ? ' week ago' : ' weeks ago');
    }
    interval = seconds / 86400; // Number of seconds in a day
    if (interval >= 1) {
      const days = Math.floor(interval);
      return days + (days === 1 ? ' day ago' : ' days ago');
    }
    interval = seconds / 3600; // Number of seconds in an hour
    if (interval >= 1) {
      const hours = Math.floor(interval);
      return hours + (hours === 1 ? ' hour ago' : ' hours ago');
    }
    interval = seconds / 60; // Number of seconds in a minute
    if (interval >= 1) {
      const minutes = Math.floor(interval);
      return minutes + (minutes === 1 ? ' minute ago' : ' minutes ago');
    }
    return (
      Math.floor(seconds) + (seconds === 1 ? ' second ago' : ' seconds ago')
    );
  };

  const youtubeVideoEmbed = (videoId) => (
    <iframe
      width="560"
      height="315"
      src={'https://www.youtube.com/embed/' + videoId}
      title="YouTube video player"
      frameborder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
      referrerpolicy="strict-origin-when-cross-origin"
      allowfullscreen
    ></iframe>
  );

  return (
    <div className="youtube-carousel">
      {console.log('video details', videoDetails)}
      {console.log('videos', videos)}
      <Slider {...sliderSettings} ref={slickSlider}>
        {videos?.map((video, i) => (
          <div className="youtube-carousel__video" key={i}>
            <div className="youtube-carousel__video-thumbnail-container">
              <Modal
                className="youtube-carousel__video-modal"
                trigger={
                  <div>
                    <Image
                      className="youtube-carousel__video-thumbnail"
                      filename={video.thumbnailUrl}
                    />
                    <div className="youtube-carousel__video-duration">
                      {video.duration}
                    </div>
                  </div>
                }
              >
                {youtubeVideoEmbed(video.id)}
              </Modal>
            </div>
            <Modal
              className="youtube-carousel__video-modal"
              trigger={
                <div className="youtube-carousel__video-title">
                  {video.title}
                </div>
              }
            >
              {youtubeVideoEmbed(video.id)}
            </Modal>
            <div className="youtube-carousel__video-metadata">
              {video.views} views &#183; {timeSince(video.uploadDate)}
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
};

YouTubeCarousel.propTypes = {
  /** Override default settings. See https://react-slick.neostack.com/docs/api/ and http://kenwheeler.github.io/slick/*/
  settings: PropTypes.object,
  playlistId: PropTypes.string.isRequired,
};

export default YouTubeCarousel;
