import useYoutubeApi from './useYouTubeApi';

/**
 * Custom hook to fetch YouTube playlist items.
 *
 * @param {string} playlistID - YouTube playlist ID.
 * @param {string} [part='contentDetails,snippet'] - Parts of the playlist items to fetch.
 * @returns {Object} - The state containing playlist details, loading status, and any error.
 *
 * @example
 * const { playlistDetails, isLoading, error } = useYouTubePlaylistItems('playlistId123');
 */
function useYouTubePlaylistItems(
  playlistID,
  part = 'contentDetails, snippet',
  maxResults = 50
) {
  const { data, isLoading, error } = useYoutubeApi('playlistItems', {
    part,
    playlistId: playlistID,
    maxResults,
  });

  return { playlistDetails: data?.items, isLoading, error };
}

export default useYouTubePlaylistItems;
