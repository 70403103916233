import useYoutubeApi from './useYouTubeApi';

/**
 * Custom hook to fetch YouTube video details.
 *
 * @param {Array} videoIds - Array of YouTube video IDs.
 * @param {string} [part='contentDetails,snippet,statistics'] - Parts of the playlist items to fetch.
 * @returns {Object} - The state containing video details, loading status, and any error.
 *
 * @example
 * const { videoDetails, isLoading, error } = useYouTubeVideos(['videoId1', 'videoId2']);
 *
 */
function useYouTubeVideos(
  videoIds,
  part = 'contentDetails,snippet,statistics'
) {
  const { data, isLoading, error } = useYoutubeApi('videos', {
    part,
    id: videoIds.join(','),
  });

  return { videoDetails: data?.items, isLoading, error };
}

export default useYouTubeVideos;
